export default [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/landing_page"),
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("@/views/about"),
  },
  {
    path: "/profile",
    name: "profil",
    component: () => import("@/views/about"),
  },
  {
    path: "/about-us",
    name: "about-us",
    component: () => import("@/views/about"),
  },
  {
    path: "/about-us",
    name: "tentang-kami",
    component: () => import("@/views/about"),
  },
  {
    path: "/governance",
    name: "governance",
    component: () => import("@/views/governance"),
  },
  {
    path: "/governance",
    name: "tata-kelola",
    component: () => import("@/views/governance"),
  },
  {
    path: "/steering-commitee",
    name: "steering-commitee",
    component: () => import("@/views/steering_commitee"),
  },
  {
    path: "/steering-commitee",
    name: "komite-pengarah",
    component: () => import("@/views/steering_commitee"),
  },
  {
    path: "/supervisory-board",
    name: "supervisory-board",
    component: () => import("@/views/supervisory_board"),
  },
  {
    path: "/supervisory-board",
    name: "dewan-pengawas",
    component: () => import("@/views/supervisory_board"),
  },
  // {
  //   path: "/board",
  //   name: "board",
  //   component: () => import("@/views/board"),
  // },
  // {
  //   path: "/board",
  //   name: "dewan-direksi",
  //   component: () => import("@/views/board"),
  // },
  {
    path: "/board-of-director",
    name: "board-of-director",
    component: () => import("@/views/board_of_director"),
  },
  {
    path: "/board-of-director",
    name: "dewan-direksi",
    component: () => import("@/views/board_of_director"),
  },
  // {
  //   path: "/organization",
  //   name: "organization",
  //   component: () => import("@/views/about/organization.vue"),
  // },
  {
    path: "/thematic-program",
    name: "thematic-program",
    component: () => import("@/views/thematic_program"),
  },
  {
    path: "/thematic-program",
    name: "program-tematik",
    component: () => import("@/views/thematic_program"),
  },
  {
    path: "/thematic-program/:id",
    name: "thematic-program-detail",
    props: true,
    component: () => import("@/views/thematic_program/detail.vue"),
  },
  {
    path: "/thematic-program/:id",
    name: "program-tematik-detil",
    props: true,
    component: () => import("@/views/thematic_program/detail.vue"),
  },
  {
    path: "/windows",
    name: "windows",
    component: () => import("@/views/windows"),
  },
  {
    path: "/windows/:id",
    name: "windows-detail",
    props: true,
    component: () => import("@/views/windows/detail.vue"),
  },
  {
    path: "/project",
    name: "project",
    component: () => import("@/views/project"),
  },
  {
    path: "/project",
    name: "proyek",
    component: () => import("@/views/project"),
  },
  {
    path: "/project/:id",
    name: "project-detail",
    props: true,
    component: () => import("@/views/project/detail.vue"),
  },
  {
    path: "/project/:id",
    name: "detil-proyek",
    props: true,
    component: () => import("@/views/project/detail.vue"),
  },
  {
    path: "/services",
    name: "services",
    component: () => import("@/views/service"),
  },
  {
    path: "/services",
    name: "layanan",
    component: () => import("@/views/service"),
  },
  {
    path: "/article/:id",
    name: "article",
    props: true,
    component: () => import("@/views/article"),
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: () => import("@/views/base_text"),
  },
  {
    path: "/kebijakan-privasi",
    name: "privacy-policy",
    component: () => import("@/views/base_text"),
  },
  {
    path: "/terms-conditions",
    name: "terms-&-conditions",
    component: () => import("@/views/base_text"),
  },
  {
    path: "/terms-conditions",
    name: "terms-&-conditions",
    component: () => import("@/views/base_text"),
  },
  {
    path: "/contact-us",
    name: "contact-us",
    component: () => import("@/views/contact"),
  },
  {
    path: "/contact-us",
    name: "kontak-kami",
    component: () => import("@/views/contact"),
  },
  {
    path: "/legal",
    name: "legal",
    component: () => import("@/views/law"),
  },
  {
    path: "/legal",
    name: "hukum",
    component: () => import("@/views/law"),
  },
  {
    path: "/careers",
    name: "careers",
    component: () => import("@/views/career"),
  },
  {
    path: "/careers",
    name: "karir",
    component: () => import("@/views/career"),
  },
  {
    path: "/careers-update",
    name: "karir-update",
    component: () => import("@/views/career"),
  },
  {
    path: "/careers/:id",
    name: "career-detail",
    props: true,
    component: () => import("@/views/career/detail.vue"),
  },
  {
    path: "/procurement",
    name: "procurement",
    component: () => import("@/views/procurement"),
  },
  {
    path: "/procurement",
    name: "pengadaan",
    component: () => import("@/views/procurement"),
  },
  {
    path: "/procurement/:id",
    name: "procurement-detail",
    props: true,
    component: () => import("@/views/procurement/detail"),
  },
  {
    path: "/procurement/:id",
    name: "detail-pengadaan",
    props: true,
    component: () => import("@/views/base_text"),
  },
  {
    path: "/news",
    name: "news",
    component: () => import("@/views/news"),
  },
  {
    path: "/news/:id",
    name: "news-detail",
    props: true,
    component: () => import("@/views/news/detail.vue"),
  },
  {
    path: "/result/:search",
    name: "result",
    props: true,
    component: () => import("@/views/result"),
  },
  {
    path: "/publication",
    name: "publication",
    component: () => import("@/views/publication"),
  },
  {
    path: "/publication",
    name: "publikasi",
    component: () => import("@/views/publication"),
  },
  {
    path: "/publication/:id",
    props: true,
    name: "public-detail",
    component: () => import("@/views/publication/detail.vue"),
  },
  {
    path: "/public-information",
    name: "public-information",
    component: () => import("@/views/public_information"),
  },
  {
    path: "/announcement",
    name: "announcement",
    component: () => import("@/views/announcement"),
  },
  {
    path: "/public-information/:id",
    name: "public-information-detail",
    props: true,
    component: () => import("@/views/article"),
  },
  {
    path: "/programs",
    name: "programs",
    component: () => import("@/views/program"),
  },
  {
    path: "/program/:id",
    name: "program-detail",
    props: true,
    component: () => import("@/views/program/detail.vue"),
  },
  {
    path: "/performances",
    name: "performances",
    component: () => import("@/views/performance"),
  },
  {
    path: "/performances",
    name: "kinerja",
    component: () => import("@/views/performance"),
  },
  {
    path: "/performances/:id",
    name: "performances-detail",
    props: true,
    component: () => import("@/views/performance/detail"),
  },
  {
    path: "/events",
    name: "event",
    component: () => import("@/views/event"),
  },
  {
    path: "/events/:id",
    name: "event-detail",
    props: true,
    component: () => import("@/views/event/detail"),
  },
  {
    path: "/sectors",
    name: "sector",
    component: () => import("@/views/sector"),
  },
  {
    path: "/sectors/:id",
    name: "sector-detail",
    props: true,
    component: () => import("@/views/sector/detail.vue"),
  },
  {
    path: "/donors",
    name: "donors",
    component: () => import("@/views/donor"),
  },
  {
    path: "/donors/:id",
    name: "donor-detail",
    props: true,
    component: () => import("@/views/donor/detail.vue"),
  },
  {
    path: "/reports",
    name: "reports",
    component: () => import("@/views/report"),
  },
  {
    path: "/reports/:id",
    name: "report-detail",
    props: true,
    component: () => import("@/views/article"),
  },
  {
    path: "/stories-blogs",
    name: "stories",
    component: () => import("@/views/stories"),
  },
  {
    path: "/blogs",
    name: "blogs",
    component: () => import("@/views/blog"),
  },
  {
    path: "/gallery",
    name: "gallery",
    component: () => import("@/views/gallery"),
  },
  {
    path: "/:type/:id",
    name: "content-detail",
    props: true,
    component: () => import("@/views/stories/detail"),
  },
  {
    path: "*",
    name: "NotFound",
    component: () => import("@/views/notFound"),
  },
  {
    path: "/no_connection",
    name: "no-connection",
    component: () => import("@/views/notFound/noConnection"),
  },
  {
    path: "/proposal",
    name: "proposal",
    component: () => import("@/views/proposal"),
  },
];
