export default {
  methods: {
    strLimit(val, max = 60, extra = '...') {
      if (val) {
        let text = val.replace(new RegExp('<[^>]*>', 'g'), '')
        if (text && text.length > max) {
          return text.slice(0, max) + extra;
        }
        return text;
      } else return '';
    },
    formatCurrency(val) {
      let value = Math.round(val);
      let number = Number(value);
      if (value) {
        if (isNaN(number)) {
          return value;
        } else {
          if (value == 0) {
            return '-';
          } else {
            return Number(value).toLocaleString('id-ID', {
              maximumFractionDigits: 2
            });
          }
        }
      }
    }
  }
}