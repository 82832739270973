import Vue from 'vue';
import Router from 'vue-router';
import paths from './path';

Vue.use(Router);

export default new Router({
  base: '/',
  mode: 'history',
  linkActiveClass: 'active',
  routes: paths,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});