<template>
  <footer>
    <div class="w-full flex flex-col lg:flex-row bg-greyLight">
      <div>
        <div class="text-start py-2 flex flex-col">
          <div class="max-w-md">
            <img
              v-if="bahasa"
              src="@/assets/images/logo_id.png"
              alt="Badan Pengelola Dana Lingkungan Hidup"
              class="w-64" />
            <img
              v-else
              src="@/assets/images/logo.png"
              alt="Indonesian Environtment Fund"
              class="w-64" />
          </div>
          <div class="px-10 max-w-md text-sm">
            <h3 v-if="bahasa">Badan Pengelola Dana Lingkungan Hidup</h3>
            <h3 v-else>Indonesian Environtment Fund</h3>
            <p>
              JB Tower lt 29-30, Jl. Kebon Sirih No. 48-50, RT 011/002, Gambir,
              Daerah Khusus Ibukota Jakarta, 10110
            </p>
          </div>
        </div>
        <div class="text-start flexflex-col">
          <div class="ps-10">
            <h4 v-if="bahasa" class="m-0">Media Sosial</h4>
            <h4 v-else class="m-0">Social Media</h4>
          </div>
          <div class="pt-1 px-10">
            <v-btn
              icon
              @click="toSocmed('https://facebook.com/' + contact.link_fb)"
              class="cursor-pointer">
              <v-icon color="grey darken-4">facebook</v-icon>
            </v-btn>
            <v-btn
              icon
              class="cursor-pointer"
              @click="toSocmed('https://instagram.com/' + contact.link_ig)">
              <img src="@/assets/images/Instagram.png" width="25" />
            </v-btn>
            <v-btn
              icon
              class="mr-6 cursor-pointer"
              @click="
                toSocmed(
                  'https://www.linkedin.com/company/' + contact.link_linkedin
                )
              ">
              <img src="@/assets/images/LI-In-Bug.png" width="25" />
            </v-btn>
          </div>
          <div class="ps-10 mt-2">
            <h4 v-if="bahasa" class="m-0">Media Pelaporan</h4>
            <h4 v-else class="m-0">Report Channel</h4>
          </div>
          <div class="pt-3 flex gap-3 px-10">
            <span
              class="w-20 cursor-pointer"
              @click="toSocmed('https://sp4n.lapor.go.id/')">
              <img src="@/assets/images/sp4n.lapor.go.id.png" alt="" />
            </span>
            <span
              class="w-20 cursor-pointer"
              @click="toSocmed('https://sp4n.lapor.go.id/')">
              <img src="@/assets/images/hai.kemenkeu.jpg" alt="" />
            </span>
            <span
              class="w-20 cursor-pointer"
              @click="toSocmed('https://sp4n.lapor.go.id/')">
              <img src="@/assets/images/gol.kpk.go.id.png" alt="" />
            </span>
            <span
              class="w-20 cursor-pointer"
              @click="toSocmed('https://sp4n.lapor.go.id/')">
              <img src="@/assets/images/wise.kemenkeu.go.id.png" alt="" />
            </span>
          </div>
        </div>
      </div>

      <!-- Menu -->
      <div class="w-full py-5 flex justify-center">
        <ul class="flex flex-col sm:flex-row list-none">
          <li
            v-for="(i, index) in menusChild"
            :key="index"
            class="text-start ps-10 group">
            <span v-if="i.route != '/' && i.have_child.length > 0">
              <span v-if="bahasa" class="font-bold text-lg">{{
                i.name_id
              }}</span>
              <span v-else class="font-bold text-lg">{{ i.name_en }}</span>
            </span>
            <span v-else class="group-hidden"></span>
            <ul
              v-if="i.parent_id === null && i.have_child.length > 0"
              class="pa-0">
              <li
                v-for="(i, index) in i.have_child"
                :key="index"
                class="text-start py-2">
                <span
                  class="cursor-pointer hover:text-primary"
                  @click="$router.push({ name: i.route })"
                  v-if="bahasa">
                  {{ i.name_id }}
                </span>
                <span
                  class="cursor-pointer hover:text-primary"
                  @click="$router.push({ name: i.route })"
                  v-else>
                  {{ i.name_en }}
                </span>
              </li>
            </ul>
          </li>
          <li class="ps-10">
            <ul class="flex flex-col align-start pa-0">
              <li
                v-for="(i, index) in menus"
                :key="index"
                class="text-start pb-2">
                <span v-if="i.route != '/' && i.have_child.length < 1">
                  <span
                    class="font-bold text-lg cursor-pointer hover:text-primary"
                    @click="$router.push({ name: i.route })"
                    v-if="bahasa">
                    {{ i.name_id }}
                  </span>
                  <span
                    class="font-bold text-lg cursor-pointer hover:text-primary"
                    @click="$router.push({ name: i.route })"
                    v-else>
                    {{ i.name_en }}
                  </span>
                </span>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <div
      class="w-full bg-greyDark flex justify-between align-center px-10 h-16">
      <span v-if="bahasa"
        >© 2023 | BPDLH | Badan Pengelola Dana Lingkungan Hidup</span
      >
      <span v-else>© 2023 | IEF | Indonesian Environment Fund</span>

      <button
        @click="$vuetify.goTo('#nav')"
        style="margin-top: -30px"
        class="px-3 py-3 btn-scroll font-medium ml-3 mb-10">
        <v-icon color="white" class="hover:primary">arrow_upward</v-icon>
      </button>
    </div>
  </footer>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  props: {
    bahasa: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      contact: null,
      menus: {},
      menusChild: {},
      baseUrl: process.env.VUE_APP_BASE_URL,
    };
  },
  watch: {
    language() {
      this.getMenus();
      this.getMenusChild();
    },
  },
  computed: {
    checkRoute() {
      let path = this.$route.fullPath;
      let page = path.split("/").join("");
      return page;
    },
    language() {
      return this.bahasa ? "ID" : "ENG";
    },
  },
  mounted() {
    this.getContact();
    this.getMenus();
    this.getMenusChild();
  },
  methods: {
    setBahasa(val) {
      this.$emit("setBahasa", val);
    },
    async getContact() {
      let param = {
        lang: this.language,
      };
      await axios
        .get(this.baseUrl + "/contact_us", { params: param })
        .then((response) => {
          if (response.data.code === 200) {
            this.contact = response.data.data;
          }
        })
        .catch((error) => {
          Swal.fire("Failed!", error.response.data.message, "error");
        })
        .finally(() => (this.isLoad = false));
    },
    async getMenus() {
      axios
        .get(this.baseUrl + "/getWebMenusFooter")
        .then((response) => {
          if (response.data.code == 200) {
            this.menus = response.data.data;
          } else {
            Swal.fire("Failed!", response.data.message, "error");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getMenusChild() {
      axios
        .get(this.baseUrl + "/getWebMenusFooter", {
          params: { has_child: true },
        })
        .then((response) => {
          if (response.data.code == 200) {
            this.menusChild = response.data.data;
          } else {
            Swal.fire("Failed!", response.data.message, "error");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    toSocmed(link) {
      window.open(link, "_blank");
    },
  },
};
</script>
