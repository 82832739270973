<template>
  <div class="relative z-10" id="nav">
    <div
      class="w-full mx-auto grid items-center justify-items-center pa-0 fixed bg-white"
      style="border-bottom: 1px solid rgba(35, 39, 42, 0.1)">
      <v-col cols="11" class="pa-0">
        <div class="container mx-auto flex items-center justify-between pa-0">
          <router-link :to="{ name: 'home' }">
            <img
              v-if="bahasa"
              src="@/assets/images/logo_id.png"
              width="150px"
              alt="Badan Pengelola Dana Lingkungan Hidup" />
            <img
              v-else
              src="@/assets/images/logo.png"
              width="150px"
              alt="Indonesian Environment Fund" />
          </router-link>
          <button @click="showMenu = !showMenu" class="lg:hidden">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
          <!-- Menu LG -->
          <div
            class="hidden bg-white absolute top-16 left-0 w-full h-auto lg:w-auto lg:bg-none lg:flex lg:static lg:top-auto lg:left-auto lg:items-center px-2 py-4">
            <div class="md:space-x-1 lg:space-x-1 2xl:space-x-5">
              <v-menu
                open-on-hover
                open-on-click
                offset-y
                v-for="items in menus.data"
                :key="items.key">
                <template
                  v-slot:activator="{ on, attrs }"
                  v-if="items.parent_id == null && items.have_child.length > 0">
                  <v-hover v-slot="{ hover }">
                    <button
                      plain
                      :class="hover ? 'active-menu' : setClass(items.route)"
                      class="w-full lg:w-auto text-capitalize text-sm md:text-sm 2xl:text-base"
                      v-bind="attrs"
                      v-on="on">
                      <span v-if="bahasa">{{ items.name_id }}</span>
                      <span v-else>{{ items.name_en }}</span>
                      <v-icon
                        :color="hover ? 'white' : setColorIcon(items.route)"
                        >expand_more</v-icon
                      >
                    </button>
                  </v-hover>
                </template>
                <template
                  v-slot:activator="{ on, attrs }"
                  v-else-if="items.route === '/'">
                  <v-hover v-slot="{ hover }">
                    <button
                      plain
                      @click="$router.push({ name: 'home' })"
                      :class="hover ? 'active-menu' : setClass(items.route)"
                      class="w-full lg:w-auto text-capitalize text-sm md:text-sm 2xl:text-base"
                      v-bind="attrs"
                      v-on="on">
                      <span v-if="bahasa">{{ items.name_id }}</span>
                      <span v-else>{{ items.name_en }}</span>
                      <v-icon
                        v-if="
                          items.parent_id == null && items.have_child.length > 0
                        "
                        :color="hover ? 'white' : setColorIcon(items.route)"
                        >expand_more</v-icon
                      >
                    </button>
                  </v-hover>
                </template>
                <template
                  v-slot:activator="{ on, attrs }"
                  v-else-if="items.route.substr(0, 1) === 'h'">
                  <v-hover v-slot="{ hover }">
                    <button
                      plain
                      @click="goToUrl(items.route)"
                      :class="hover ? 'active-menu' : setClass(items.route)"
                      class="w-full lg:w-auto text-capitalize text-sm md:text-sm 2xl:text-base"
                      v-bind="attrs"
                      v-on="on">
                      <span v-if="bahasa">{{ items.name_id }}</span>
                      <span v-else>{{ items.name_en }}</span>
                      <v-icon
                        v-if="
                          items.parent_id == null && items.have_child.length > 0
                        "
                        :color="hover ? 'white' : setColorIcon(items.route)"
                        >expand_more</v-icon
                      >
                    </button>
                  </v-hover>
                </template>
                <template v-slot:activator="{ on, attrs }" v-else>
                  <v-hover v-slot="{ hover }">
                    <button
                      plain
                      @click="$router.push({ name: items.route })"
                      :class="hover ? 'active-menu' : setClass(items.route)"
                      class="w-full lg:w-auto text-capitalize text-sm md:text-sm 2xl:text-base"
                      v-bind="attrs"
                      v-on="on">
                      <span v-if="bahasa">{{ items.name_id }}</span>
                      <span v-else>{{ items.name_en }}</span>
                      <v-icon
                        v-if="
                          items.parent_id == null && items.have_child.length > 0
                        "
                        :color="hover ? 'white' : setColorIcon(items.route)"
                        >expand_more</v-icon
                      >
                    </button>
                  </v-hover>
                </template>

                <v-list
                  class="text-left"
                  v-if="items.parent_id == null && items.have_child.length > 0">
                  <v-list-item
                    :to="{ name: chil.route }"
                    v-for="chil in items.have_child"
                    :key="chil.id">
                    <v-list-item-title>
                      <span v-if="bahasa" class="text-sm 2xl:text-base">{{
                        chil.name_id
                      }}</span>
                      <span v-else class="text-sm 2xl:text-base">{{
                        chil.name_en
                      }}</span>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            <div class="md:space-x-1 lg:space-x-1 2xl:space-x-5 lg:ml-5">
              <v-menu open-on-hover offset-y :close-on-content-click="true">
                <template v-slot:activator="{ on, attrs }">
                  <v-hover v-slot="{ hover }">
                    <button
                      plain
                      :class="hover ? 'active-menu' : setClass('search')"
                      class="w-full lg:w-auto"
                      v-bind="attrs"
                      v-on="on">
                      <v-icon :color="hover ? 'white' : setColorIcon('search')"
                        >search</v-icon
                      >
                    </button>
                  </v-hover>
                </template>
                <v-list>
                  <v-list-item>
                    <v-text-field
                      v-model="search"
                      placeholder="Search"
                      outlined
                      hide-details
                      dense
                      append-icon="search"
                      color="green darken-1"
                      @keyup.enter="searchPage" />
                  </v-list-item>
                </v-list>
              </v-menu>

              <span class="text-2xl text-greyLight hidden lg:inline">|</span>
              <v-menu open-on-hover offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <div class="btn-navbar" v-bind="attrs" v-on="on">
                    <v-icon color="grey darken-3" small>language</v-icon>
                    <span v-if="bahasa" class="text-sm md:text-sm 2xl:text-base"
                      >ID</span
                    >
                    <span v-else class="text-sm md:text-sm 2xl:text-base"
                      >EN</span
                    >
                    <v-icon color="grey darken-3">expand_more</v-icon>
                  </div>
                </template>

                <v-list class="text-left">
                  <v-list-item @click="setBahasa(false)">
                    <v-list-item-title class="text-sm md:text-sm 2xl:text-base"
                      >English</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item @click="setBahasa(true)">
                    <v-list-item-title class="text-sm md:text-sm 2xl:text-base"
                      >Indonesia</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
          <!-- End Menu LG -->
          <div
            class="bg-white absolute top-16 left-0 w-full h-auto lg:w-auto lg:bg-none lg:hidden lg:static lg:top-auto lg:left-auto lg:items-center px-2 py-4"
            :class="showMenu ? 'block' : 'hidden'">
            <div class="lg:space-x-5">
              <v-menu
                open-on-click
                offset-y
                v-for="items in menus.data"
                :key="items.key">
                <!-- Menu With Child -->
                <template
                  v-slot:activator="{ on, attrs }"
                  v-if="items.parent_id == null && items.have_child.length > 0">
                  <v-hover v-slot="{ hover }">
                    <button
                      plain
                      :class="hover ? 'active-menu' : setClass(items.route)"
                      class="w-full lg:w-auto text-capitalize"
                      v-bind="attrs"
                      v-on="on">
                      <span v-if="bahasa">{{ items.name_id }}</span>
                      <span v-else>{{ items.name_en }}</span>
                      <v-icon
                        :color="hover ? 'white' : setColorIcon(items.route)"
                        >expand_more</v-icon
                      >
                    </button>
                  </v-hover>
                </template>
                <!-- End Menu With Child -->

                <!-- Dashboard -->
                <template
                  v-slot:activator="{ on, attrs }"
                  v-else-if="items.route === '/'">
                  <v-hover v-slot="{ hover }">
                    <button
                      plain
                      @click="goTo('home')"
                      :class="hover ? 'active-menu' : setClass(items.route)"
                      class="w-full lg:w-auto text-capitalize"
                      v-bind="attrs"
                      v-on="on">
                      <span v-if="bahasa">{{ items.name_id }}</span>
                      <span v-else>{{ items.name_en }}</span>
                      <v-icon
                        v-if="
                          items.parent_id == null && items.have_child.length > 0
                        "
                        :color="hover ? 'white' : setColorIcon(items.route)"
                        >expand_more</v-icon
                      >
                    </button>
                  </v-hover>
                </template>
                <!-- End Dashboard -->

                <!-- External Link -->
                <template
                  v-slot:activator="{ on, attrs }"
                  v-else-if="items.route.substr(0, 1) === 'h'">
                  <v-hover v-slot="{ hover }">
                    <button
                      plain
                      @click="goToUrl(items.route)"
                      :class="hover ? 'active-menu' : setClass(items.route)"
                      class="w-full lg:w-auto text-capitalize"
                      v-bind="attrs"
                      v-on="on">
                      <span v-if="bahasa">{{ items.name_id }}</span>
                      <span v-else>{{ items.name_en }}</span>
                      <v-icon
                        v-if="
                          items.parent_id == null && items.have_child.length > 0
                        "
                        :color="hover ? 'white' : setColorIcon(items.route)"
                        >expand_more</v-icon
                      >
                    </button>
                  </v-hover>
                </template>
                <!-- End External Link -->

                <template v-slot:activator="{ on, attrs }" v-else>
                  <v-hover v-slot="{ hover }">
                    <button
                      plain
                      @click="goTo(items.route)"
                      :class="hover ? 'active-menu' : setClass(items.route)"
                      class="w-full lg:w-auto text-capitalize"
                      v-bind="attrs"
                      v-on="on">
                      <span v-if="bahasa">{{ items.name_id }}</span>
                      <span v-else>{{ items.name_en }}</span>
                      <v-icon
                        v-if="
                          items.parent_id == null && items.have_child.length > 0
                        "
                        :color="hover ? 'white' : setColorIcon(items.route)"
                        >expand_more</v-icon
                      >
                    </button>
                  </v-hover>
                </template>
                <v-list
                  class="text-left"
                  v-if="items.parent_id == null && items.have_child.length > 0">
                  <v-list-item
                    v-for="chil in items.have_child"
                    @click="goTo(chil.route)"
                    :key="chil.id">
                    <v-list-item-title>
                      <span v-if="bahasa">{{ chil.name_id }}</span>
                      <span v-else>{{ chil.name_en }}</span>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            <div class="lg:space-x-5 lg:ml-5">
              <v-menu open-on-click offset-y :close-on-content-click="true">
                <template v-slot:activator="{ on, attrs }">
                  <v-hover v-slot="{ hover }">
                    <button
                      plain
                      :class="hover ? 'active-menu' : setClass('search')"
                      class="w-full lg:w-auto"
                      v-bind="attrs"
                      v-on="on">
                      <v-icon :color="hover ? 'white' : setColorIcon('search')"
                        >search</v-icon
                      >
                    </button>
                  </v-hover>
                </template>
                <v-list>
                  <v-list-item>
                    <v-text-field
                      v-model="search"
                      placeholder="Search"
                      outlined
                      hide-details
                      dense
                      append-icon="search"
                      color="green darken-1"
                      @keyup.enter="searchPage" />
                  </v-list-item>
                </v-list>
              </v-menu>

              <span class="text-2xl text-greyLight hidden lg:inline">|</span>
              <v-menu open-on-click offset-y :close-on-content-click="true">
                <template v-slot:activator="{ on, attrs }">
                  <div class="btn-navbar" v-bind="attrs" v-on="on">
                    <v-icon color="grey darken-3" small>language</v-icon>
                    <span v-if="bahasa">ID</span>
                    <span v-else>EN</span>
                    <v-icon color="grey darken-3">expand_more</v-icon>
                  </div>
                </template>

                <v-list class="text-left">
                  <v-list-item @click="setBahasa(false)">
                    <v-list-item-title>English</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="setBahasa(true)">
                    <v-list-item-title>Indonesia</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
        </div>
      </v-col>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { RouterLink } from "vue-router";
// import $ from "jquery";

export default {
  props: {
    bahasa: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showMenu: false,
      search: "",
      baseUrl: process.env.VUE_APP_BASE_URL,
      menus: [],
    };
  },
  mounted() {
    this.getMenus();
    if (!this.isOnLine) {
      this.$router.push({ name: "no-connection" });
    }
  },
  computed: {
    isOnLine() {
      return navigator.onLine;
    },
    checkRoute() {
      let path = this.$route.fullPath;
      let page = path.split("/").join("");
      return page;
    },
  },
  methods: {
    goTo(route) {
      this.$router.push({
        name: route,
      });
      this.showMenu = false;
    },
    goToUrl(url) {
      window.open(url, "_blank");
    },
    searchPage() {
      this.$router.push({
        name: "result",
        params: {
          search: this.search,
        },
      });
    },
    setBahasa(val) {
      this.$emit("setBahasa", val);
      this.showMenu = false;
    },
    setClass(type) {
      let menuClass = "default-menu";
      switch (type) {
        case "/":
          menuClass =
            this.checkRoute === "/" || this.checkRoute === ""
              ? "active-menu"
              : "default-menu";
          break;
        case "profile":
          menuClass =
            this.checkRoute === "about-us" ||
            this.checkRoute === "governance" ||
            this.checkRoute === "steering-commitee" ||
            this.checkRoute === "supervisory-board" ||
            this.checkRoute === "board-of-director"
              ? "active-menu"
              : "default-menu";
          break;
        case "what-we-do":
          menuClass =
            this.checkRoute === "thematic-program" ||
            this.checkRoute === "windows" ||
            this.checkRoute.match("windows") ||
            this.checkRoute === "services" ||
            this.checkRoute.match("services") ||
            this.checkRoute === "project" ||
            this.checkRoute.match("project")
              ? "active-menu"
              : "default-menu";
          break;
        case "public-information":
          menuClass =
            this.checkRoute === "news" ||
            this.checkRoute === "blogs" ||
            this.checkRoute === "gallery" ||
            this.checkRoute === "reports" ||
            this.checkRoute === "stories" ||
            this.checkRoute === "publication" ||
            this.checkRoute === "announcement"
              ? "active-menu"
              : "default-menu";
          break;
        case "contact-us":
          menuClass =
            this.checkRoute === "contact-us" ? "active-menu" : "default-menu";
          break;
        case "careers":
          menuClass =
            this.checkRoute === "careers" ? "active-menu" : "default-menu";
          break;
        case "procurement":
          menuClass =
            this.checkRoute === "procurement" ? "active-menu" : "default-menu";
          break;
      }
      return menuClass;
    },
    setColorIcon(type) {
      let iconColor = "grey darken-4";
      switch (type) {
        case "profile":
          iconColor =
            this.checkRoute === "about-us" ||
            this.checkRoute === "governance" ||
            this.checkRoute === "steering-commitee" ||
            this.checkRoute === "supervisory-board" ||
            this.checkRoute === "board-of-director"
              ? "white"
              : "grey darken-4";
          break;
        case "what-we-do":
          iconColor =
            this.checkRoute === "thematic-program" ||
            this.checkRoute === "windows" ||
            this.checkRoute.match("windows") ||
            this.checkRoute === "services" ||
            this.checkRoute.match("services") ||
            this.checkRoute === "project" ||
            this.checkRoute.match("project")
              ? "white"
              : "grey darken-4";
          break;
        case "public-information":
          iconColor =
            this.checkRoute === "news" ||
            this.checkRoute === "blogs" ||
            this.checkRoute === "gallery" ||
            this.checkRoute === "reports" ||
            this.checkRoute === "stories" ||
            this.checkRoute === "publication" ||
            this.checkRoute === "announcement"
              ? "white"
              : "grey darken-4";
          break;
        case "contact-us":
          iconColor =
            this.checkRoute === "careers" ||
            this.checkRoute === "procurement" ||
            this.checkRoute === "contact-us"
              ? "white"
              : "grey darken-4";
          break;
      }
      return iconColor;
    },
    getMenus() {
      axios
        .get(this.baseUrl + "/getWebMenus")
        .then((response) => {
          this.menus = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  components: { RouterLink },
};
</script>
<style>
.btn-navbar {
  @apply block lg:inline py-1 px-2 tracking-wide cursor-pointer;
}
</style>
