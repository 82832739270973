<template>
  <div id="app">
    <v-app id="inspire" class="app">
      <app-toolbar
        class="app--toolbar"
        :bahasa="bahasa"
        @setBahasa="setBahasa"></app-toolbar>
      <router-view :bahasa="bahasa" />
      <!-- <Footer :bahasa="bahasa" @setBahasa="setBahasa"></Footer> -->
      <NewFooter :bahasa="bahasa" @setBahasa="setBahasa" />
    </v-app>
  </div>
</template>

<script>
import AppToolbar from "@/components/AppToolbar";
// import Footer from "@/components/Footer";
import NewFooter from "./components/NewFooter.vue";

export default {
  name: "App",
  data() {
    return {
      bahasa: true,
    };
  },
  components: {
    AppToolbar,
    // Footer,
    NewFooter,
  },
  computed: {
    isOnLine() {
      return navigator.onLine;
    },
  },
  watch: {
    bahasa(val) {
      this.bahasa = val;
    },
  },
  mounted() {
    if (!this.isOnLine) {
      this.$router.push({ name: "no-connection" });
    }
  },
  methods: {
    setBahasa(val) {
      this.bahasa = val;
    },
  },
};
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
