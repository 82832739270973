<template>
  <div class="preloader" id="preloader" v-show="show">
    <div class="preloader-content">
      <!-- <img
        src="@/assets/images/loading.gif"
        style="width: 170%"
        alt="preloader"
      /> -->
      <v-progress-circular
        :size="70"
        :width="10"
        indeterminate
        color="green"
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    }
  }
};
</script>

<style>
.transparent .v-dialog {
  box-shadow: none !important;
}
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000061;
  z-index: 9999;
  transition: background-color 2s ease-out;
}
.preloader-content {
  position: absolute;
  top: 50%;
  width: 50px;
  left: 0;
  right: 0;
  margin: 0 auto;
  margin-top: -50px;
}
</style>
