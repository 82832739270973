import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import "@/assets/css/tailwind.css";
import "@/assets/css/main.css";
import App from "./App.vue";
import router from "./router";
import ProgressDialog from "@/components/ProgressDialog";
import PerfectScrollbar from "vue-perfect-scrollbar";
import axios from "axios";
import filter from "@/mixins/filter.js";

Vue.use(Vuetify);
Vue.component("progress-dialog", ProgressDialog);
Vue.component("v-perfect-scroll", PerfectScrollbar);
Vue.mixin(filter);
axios.defaults.headers.common["Authorization"] =
  "Bearer a43c5b252a28f5b7af6dfbc2c7f122d4c7d78f3e";
// Vue.use(axios);
Vue.config.productionTip = false;

if (window !== window.top) {
  window.top.location = window.location;
}

new Vue({
  router,
  vuetify: new Vuetify(),
  render: (h) => h(App),
}).$mount("#app");
